<template>
  <Modal
    v-model="isShow"
    title="奖金提现申请驳回"
    class-name="i-modal"
    width="700px"
  >
    <Form
      :model="obj"
      class="modelViewFrom"
      ref="form"
      label-position="left"
      :label-width="80"
    >
      <Row :gutter="16">
        <Col span="12">
          <FormItem prop="MemberCode" label="会员编号">
            <Input type="text" v-model="obj.MemberCode" disabled></Input>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem prop="DrawDate" label="申请时间">
            <Input type="text" v-model="obj.DrawDate" disabled></Input>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="12">
          <FormItem prop="Amount" label="奖金">
            <Input type="text" v-model="obj.Amount" disabled></Input>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem prop="StatusCN" label="状态">
            <Input type="text" v-model="obj.StatusCN" disabled></Input>
          </FormItem>
        </Col>
      </Row>
      <!-- <Row :gutter="16">
        <Col span="12">
        <FormItem prop="HandleAmount"
                  label="服务费">
          <Input type="text"
                 v-model="obj.HandleAmount"
                 disabled></Input>
        </FormItem>
        </Col>
        <Col span="12">
        <FormItem prop="RealAmount"
                  label="应支付金额">
          <Input type="text"
                 v-model="obj.RealAmount"
                 disabled></Input>
        </FormItem>
        </Col>
      </Row> -->
      <Row :gutter="16">
        <Col span="12">
          <FormItem prop="BankName" label="银行名称">
            <Input type="text" v-model="obj.BankName" disabled></Input>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem prop="BankCard" label="银行卡号">
            <Input type="text" v-model="obj.BankCard" disabled></Input>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="24">
          <FormItem prop="RejectReason" label="驳回理由">
            <Input type="text" v-model="obj.RejectReason"></Input>
          </FormItem>
        </Col>
      </Row>
    </Form>
    <div slot="footer">
      <Button @click="isShow = false">取消</Button>
      <Button
        v-if="isEdit || isAdd"
        type="primary"
        @click="handleSave"
        :loading="SaveLoading"
      >
        {{ !SaveLoading ? "驳回" : "驳回中..." }}
      </Button>
    </div>
  </Modal>
</template>
<script>
import api from "@/api";
import bizEnum from "@/libs/bizEnum";
export default {
  name: "add-logistics-modal",
  data() {
    return {
      isShow: false,
      isEdit: false,
      isAdd: true,
      SaveLoading: false,
      loading: false,
      files: [],
      obj: {},
    };
  },
  methods: {
    onChange(opt) {
      this.files = opt.files;
    },
    async handleSave() {
      const _this = this;
      try {
        _this.SaveLoading = true;
        const response = await api.PayBonusDraw({
          id: this.obj.ID,
          PaySerial: this.obj.PaySerial,
          PayMoney: this.obj.Amount,
          PayType: this.obj.PayType,
          no: "true",
          RejectReason: this.obj.RejectReason,
        });
        if (response.Status === 100) {
          _this.$Message.success("驳回成功！");
          _this.SaveLoading = false;
          _this.isShow = false;
          _this.$emit("on-save", this.obj);
        } else {
          _this.SaveLoading = false;
          _this.isShow = false;
          _this.$Message.error(response.Msg);
        }
      } catch (error) {
        _this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.SaveLoading = false;
      }
    },
  },
  watch: {
    isShow: function (val) {
      if (val && this.obj.Logo) {
        let files = [
          {
            _key: this.obj.Logo,
            _file: this.$setImagePrefix(this.obj.Logo),
            success: true,
          },
        ];
        this.files = this.$refs.UploadLogistic.uploadFiles = files;
      } else {
        this.files = [];
      }
    },
  },
  computed: {
    PayTypeEnum() {
      return bizEnum.PayTypeEnum;
    },
  },
  components: {},
};
</script>
